<template>
<div>
    {{ $t('auth.logout') }}
  </div>
</template>

<script>


export default {
mounted() {
    this.$store.dispatch('auth/signOut')
    this.$router.push({ name: '/' })
  },
}
</script>
